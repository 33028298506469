import type { ContentItemInterface } from '@/interfaces';
import { BulletPontContainer, ContentWrapper, HyperLink, ImageWrapper, SubTitle, Title } from './BulletPoints.styles';
import Arrow from '../../../../public/images/icons/arrow_forward.svg';

const BulletPoints: React.FC<ContentItemInterface> = ({ title, subTitle, buttonText, buttonLink, openInNewTab }) => {
  return (
    <>
      <BulletPontContainer>
        <ImageWrapper>
          <Arrow width='24' height='24' />
        </ImageWrapper>
        <ContentWrapper>
          <Title>{title}</Title>
          <SubTitle>{subTitle}</SubTitle>
          <HyperLink href={buttonLink} openInNewTab={openInNewTab}>
            {buttonText}
          </HyperLink>
        </ContentWrapper>
      </BulletPontContainer>
    </>
  );
};

export default BulletPoints;
